<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="Cititor" class="px-5 py-3">
          <v-form>
            <v-container class="py-0" fluid>
              <v-row dense class="text-no-wrap" align="center">
                <v-col cols="12" sm="9" class="order-1 order-sm-0">
                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col cols="12" sm="4">
                      <span class="d-none">{{ render }}</span>
                      <v-text-field
                        v-model="record.fields['500'][0].a[0].val"
                        :rules="validateDuplicate(true, dictionary['500a'], record.fields['500'][0].a[0].val)"
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        autofocus
                        :label="'500a ' + $t('Barcode')"
                        @blur="checkDuplicate('cln', '500', 'a', record.fields['500'][0].a[0].val)"
                      >
                        <template v-slot:append-outer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon color="primary" tabindex="-1" v-on="on" @click.stop.prevent="genBarcode('cln')">
                                mdi-refresh
                              </v-icon>
                            </template>
                            <span>{{ $t("Generare barcode") }}</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-text-field
                        v-model="record.fields['200'][0].a[0].val"
                        :rules="validateValue('200', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'200a ' + $t('Nume Prenume')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select
                        v-model="record.fields['505'][0].c[0].val"
                        :rules="
                          validateValue('505', 'c', record.fields['505'][0].c[0].val, dictionary.clnStareCont, 'value')
                        "
                        :error-messages="
                          errorMessages('505', 'c', record.fields['505'][0].c[0].val, dictionary.clnStareCont, 'value')
                        "
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'505c ' + $t('Stare cont')"
                        :items="dictionary.clnStareCont"
                        @change="render++"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select
                        v-model="record.fields['505'][0].a[0].val"
                        :rules="
                          validateValue(
                            '505',
                            'a',
                            record.fields['505'][0].a[0].val,
                            dictionary.clnClasaCititor,
                            'value'
                          )
                        "
                        :error-messages="
                          errorMessages(
                            '505',
                            'a',
                            record.fields['505'][0].a[0].val,
                            dictionary.clnClasaCititor,
                            'value'
                          )
                        "
                        style="width:250px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'505a ' + $t('Clasă utilizator')"
                        :items="dictionary.clnClasaCititor"
                        @change="render++"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select
                        v-model="record.fields['505'][0].d[0].val"
                        :rules="
                          validateValue(
                            '505',
                            'd',
                            record.fields['505'][0].d[0].val,
                            dictionary.clnStarePermis,
                            'value'
                          )
                        "
                        :error-messages="
                          errorMessages(
                            '505',
                            'd',
                            record.fields['505'][0].d[0].val,
                            dictionary.clnStarePermis,
                            'value'
                          )
                        "
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'505d ' + $t('Stare permis')"
                        :items="dictionary.clnStarePermis"
                        @change="render++"
                      />
                    </v-col>
                    <v-col cols="12" sm="4" class="d-flex">
                      <div style="width:150px">
                        <s-date-time
                          v-model="record.fields['505'][0].b[0].val"
                          :rules="validateValue('505', 'b')"
                          :error-messages="errorMessages('505', 'b', record.fields['505'][0].b[0].val)"
                          :hide-details="'false'"
                          :dense="dense"
                          :label="'505b ' + $t('Data înscrierii')"
                          type="date"
                        />
                      </div>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            color="primary"
                            icon
                            :disabled="!record._id || !enabledReinscrie()"
                            v-on="on"
                            @click.stop.prevent="clnReinscrie"
                          >
                            <v-icon tabindex="-1" size="20">
                              mdi-refresh
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("Reînscrie") }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" sm="4" class="d-flex">
                      <div style="width:150px">
                        <s-date-time
                          v-model="record.fields['505'][0].e[0].val"
                          :rules="validateValue('505', 'e')"
                          :error-messages="errorMessages('505', 'e', record.fields['505'][0].e[0].val)"
                          :hide-details="hided ? 'true' : 'false'"
                          :dense="dense"
                          :label="'505e ' + $t('Ultima vizare a permisului (reînscriere)')"
                          type="date"
                        />
                      </div>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            color="primary"
                            icon
                            :disabled="!record._id || !enabledVizeaza()"
                            v-on="on"
                            @click.stop.prevent="clnViseaza"
                          >
                            <v-icon tabindex="-1" size="20">
                              mdi-credit-card-check
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("Vizează") }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" sm="4" class="mb-4">
                      <s-date-time
                        v-model="record.fields['505'][0].z[0].val"
                        :rules="validateValue('505', 'z')"
                        style="width:150px"
                        :hide-details="hided ? 'true' : 'false'"
                        :dense="dense"
                        :label="'505z ' + $t('Data expirării permisului')"
                        type="date"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="3" class="d-flex justify-center order-0 order-sm-1">
                  <add-image
                    v-model="record.fields['856'][0].link"
                    :height="150"
                    @input="record.fields['856'][0].x[0].val = 'copertă principală'"
                  />
                </v-col>
              </v-row>
              <base-material-tabs
                :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                color="primary"
                center-active
                centered
                fixed-tabs
              >
                <v-tab>{{ $t("Identificare") }}</v-tab>
                <v-tab>{{ $t("Adresa") }}</v-tab>
                <v-tab>{{ $t("Contact") }}</v-tab>
                <v-tab>{{ $t("Statut") }}</v-tab>
                <v-tab :disabled="!record._id">{{ $t("Girant") }}</v-tab>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="record.fields['204'][0].a[0].val"
                        :rules="validateValue('204', 'a')"
                        style="width:200px"
                        :error-messages="
                          errorMessages(
                            '204',
                            'a',
                            record.fields['204'][0].a[0].val,
                            dictionary.clnFormulaAdresare,
                            'value'
                          )
                        "
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'204a ' + $t('Formula de adresare')"
                        :items="dictionary.clnFormulaAdresare"
                        @change="render++"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="record.fields['100'][0].a[0].val"
                        :rules="validateValue('100', 'a')"
                        style="width:175px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'100a ' + $t('CNP')"
                        @change="setDataNasteri"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <s-date-time
                        v-model="record.fields['200'][0].b[0].val"
                        :rules="validateValue('200', 'b')"
                        style="width:125px"
                        :hide-details="hided ? 'true' : 'false'"
                        :dense="dense"
                        :label="'200b ' + $t('Data nașterii')"
                        type="date"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="record.fields['100'][0].b[0].val"
                        :rules="validateValue('100', 'b')"
                        style="width:150px"
                        :error-messages="
                          errorMessages(
                            '100',
                            'b',
                            record.fields['100'][0].b[0].val,
                            dictionary.clnActIdentitate,
                            'value'
                          )
                        "
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'100b ' + $t('Tip act identitate')"
                        :items="dictionary.clnActIdentitate"
                        @change="render++"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="d-flex">
                      <div>
                        <v-text-field
                          v-model="record.fields['100'][0].c[0].val"
                          :rules="validateValue('100', 'c')"
                          style="width:75px"
                          :hide-details="hided"
                          :dense="dense"
                          :label="'100c ' + $t('Serie')"
                        />
                      </div>
                      <div>
                        <v-text-field
                          v-model="record.fields['100'][0].d[0].val"
                          :rules="validateValue('100', 'd')"
                          style="width:92px"
                          class="ml-2"
                          :hide-details="hided"
                          :dense="dense"
                          :label="'100d ' + $t('Număr')"
                        />
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="record.fields['200'][0].g[0].val"
                        :rules="validateValue('200', 'g')"
                        style="width:125px"
                        :error-messages="
                          errorMessages(
                            '200',
                            'g',
                            record.fields['200'][0].g[0].val,
                            dictionary.clnSex,
                            'value'
                          )
                        "
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'200g ' + $t('Sex')"
                        :items="dictionary.clnSex"
                        @change="render++"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <s-autocomplete
                        v-model="record.fields['200'][0].c[0].val"
                        :rules="validateValue('200', 'c')"
                        style="width:200px"
                        :label="'200c ' + $t('Locul nașterii')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.200.c.val'"
                        :item-value="'fields.200.c.val'"
                        collection="ebib_marc_cln"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="record.fields['200'][0].d[0].val"
                        :rules="validateValue('200', 'd')"
                        style="width:175px"
                        :error-messages="
                          errorMessages(
                            '200',
                            'd',
                            record.fields['200'][0].d[0].val,
                            dictionary.clnNationalitate,
                            'value'
                          )
                        "
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'200d ' + $t('Naționalitate')"
                        :items="dictionary.clnNationalitate"
                        @change="render++"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-checkbox
                        v-model="record.fields['200'][0].e[0].val"
                        :rules="validateValue('200', 'e')"
                        class="mt-2"
                        :hide-details="hided"
                        :dense="dense"
                        true-value="Da"
                        false-value="Nu"
                        :label="'200e ' + $t('Rezident')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="record.fields['300'][0].a[0].val"
                        :rules="validateValue('300', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        rows="3"
                        :label="'300a ' + $t('Note')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row v-for="(adr, ind) in record.fields['210']" :key="'210' + ind" dense>
                    <v-subheader>
                      {{ $t("Adresa") }}
                      <div class="d-inline-flex">
                        <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.210', ind + 1)">
                          mdi-plus
                        </v-icon>
                        <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.210', ind)">
                          mdi-minus
                        </v-icon>
                      </div>
                    </v-subheader>
                    <v-col cols="12" sm="12">
                      <v-select
                        v-model="adr.t[0].val"
                        :rules="validateValue('210', 't')"
                        :error-messages="
                          errorMessages('210', 't', record.fields['210'][0].t[0].val, dictionary.TipAdresa, 'value')
                        "
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'210t ' + $t('Tip adresă')"
                        :items="dictionary.TipAdresa"
                        @change="render++"
                      >
                        <!-- <template v-slot:prepend>
                          <div class="d-inline-flex">
                            <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.210', ind + 1)">
                              mdi-plus
                            </v-icon>
                            <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.210', ind)">
                              mdi-minus
                            </v-icon>
                          </div>
                        </template> -->
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-textarea
                        v-model="adr.a[0].val"
                        :rules="validateValue('210', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        rows="2"
                        :label="'210a ' + $t('Adresa')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <s-autocomplete
                        v-model="adr.g[0].val"
                        :rules="validateValue('210', 'g')"
                        style="width:250px"
                        :label="'210g ' + $t('Localitate')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.210.g.val'"
                        :item-value="'fields.210.g.val'"
                        collection="ebib_marc_cln"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="adr.z[0].val"
                        :rules="validateValue('210', 'z')"
                        style="width:125px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'210z ' + $t('Cod poștal')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="adr.h[0].val"
                        :rules="validateValue('210', 'h')"
                        style="width:250px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'210h ' + $t('Județ')"
                        :items="dictionary.JudeteRomania"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="adr.l[0].val"
                        :rules="validateValue('210', 'l')"
                        style="width:250px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'210l ' + $t('Țara')"
                        :items="dictionary.countriesAlpha3"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12" md="8">
                      <v-row v-for="(fld, ind) in record.fields['700']" :key="'700' + ind" dense>
                        <span class="d-none">{{ render }}</span>
                        <v-col cols="12" sm="6">
                          <span class="d-none">{{ render }}</span>
                          <v-select
                            v-model="fld.a[0].val"
                            :rules="validateValue('700', 'a')"
                            style="width:300px"
                            :error-messages="
                              errorMessages(
                                '700',
                                'a',
                                record.fields['700'][0].a[0].val,
                                dictionary.tipTelefon,
                                'value'
                              )
                            "
                            :hide-details="hided"
                            :dense="dense"
                            item-text="name"
                            item-value="value"
                            :label="'700a ' + $t('Tip telefon')"
                            :items="dictionary.tipTelefon"
                            @change="render++"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <span class="d-none">{{ render }}</span>
                          <v-text-field
                            v-model="fld.n[0].val"
                            :rules="validateValue('700', 'n')"
                            style="width:300px"
                            :hide-details="hided"
                            :dense="dense"
                            :label="'700n ' + $t('Număr telefon')"
                          >
                            <template v-slot:append-outer>
                              <div class="d-inline-flex">
                                <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.700', ind + 1)">
                                  mdi-plus
                                </v-icon>
                                <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.700', ind)">
                                  mdi-minus
                                </v-icon>
                              </div>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-row dense>
                        <v-col v-for="(fld, ind) in record.fields['201']" :key="'201' + ind" cols="12">
                          <span class="d-none">{{ render }}</span>
                          <v-text-field
                            v-model="fld.n[0].val"
                            :rules="validateValue('201', 'n')"
                            style="width:300px"
                            :hide-details="hided"
                            :dense="dense"
                            :label="'201n ' + $t('Email')"
                          >
                            <template v-slot:append-outer>
                              <div class="d-inline-flex">
                                <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.201', ind + 1)">
                                  mdi-plus
                                </v-icon>
                                <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.201', ind)">
                                  mdi-minus
                                </v-icon>
                              </div>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="record.fields['205'][0].b[0].val"
                        :rules="validateValue('205', 'b')"
                        :error-messages="
                          errorMessages(
                            '205',
                            'b',
                            record.fields['205'][0].b[0].val,
                            dictionary.clnNivelPregatire,
                            'value'
                          )
                        "
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'205b ' + $t('Nivel pregătire')"
                        :items="dictionary.clnNivelPregatire"
                        @change="render++"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="record.fields['205'][0].a[0].val"
                        :rules="validateValue('205', 'a')"
                        :error-messages="
                          errorMessages(
                            '205',
                            'a',
                            record.fields['205'][0].a[0].val,
                            dictionary.utilizatorProfesie,
                            'value'
                          )
                        "
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'205a ' + $t('Statut ocupaţional')"
                        :items="dictionary.utilizatorProfesie"
                        @change="render++"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <s-autocomplete
                        v-model="record.fields['205'][0].c[0].val"
                        :rules="validateValue('205', 'c')"
                        :label="'205c ' + $t('Locul de muncă')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.205.c.val'"
                        :item-value="'fields.205.c.val'"
                        collection="ebib_marc_cln"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <s-autocomplete
                        v-model="record.fields['205'][0].f[0].val"
                        :rules="validateValue('205', 'f')"
                        :label="'205f ' + $t('Ocupaţie')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.205.f.val'"
                        :item-value="'fields.205.f.val'"
                        collection="ebib_marc_cln"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <s-autocomplete
                        v-model="record.fields['205'][0].g[0].val"
                        :rules="validateValue('205', 'g')"
                        :label="'205g ' + $t('Instituţia de învăţământ')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.205.g.val'"
                        :item-value="'fields.205.g.val'"
                        collection="ebib_marc_cln"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <s-autocomplete
                        v-model="record.fields['205'][0].d[0].val"
                        :rules="validateValue('205', 'd')"
                        :label="'205d ' + $t('Secţia')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.205.d.val'"
                        :item-value="'fields.205.d.val'"
                        collection="ebib_marc_cln"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['250'][0].a[0].val"
                        :rules="validateValue('205', 'a')"
                        style="width:100px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'250a ' + $t('An')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['250'][0].b[0].val"
                        :rules="validateValue('250', 'b')"
                        style="width:100px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'250b ' + $t('Clasă')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-subheader>{{ "410 " + $t("Girant") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['410']"
                    height="75px"
                    :render="render"
                    collection="ebib_marc_cln"
                    @add="addSubItem('fields.410', $event)"
                    @del="delSubItem('fields.410', $event, 'cln')"
                    @search="searchRelationMarc('410', 't', $event, 'cln', null)"
                  />
                  <v-subheader>{{ "411 " + $t("Girează pentru") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['411']"
                    height="75px"
                    :render="render"
                    collection="ebib_marc_cln"
                    @add="addSubItem('fields.411', $event)"
                    @del="delSubItem('fields.411', $event, 'cln')"
                    @search="searchRelationMarc('411', 't', $event, 'cln', 'reader')"
                  />
                </v-tab-item>
              </base-material-tabs>
              <!-- <v-btn @click="getInv">Get</v-btn> -->
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="setLinkValue" />
    <create-edit ref="createEdit" />
  </v-container>
</template>

<script>
import SearchLink from "../../../components/containers/SearchLink"
import CreateEdit from "../../../views/settings/AddDictionaryEdit"
import SDateTime from "../../../components/inputs/DateTime"
import SAutocomplete from "../../../components/inputs/Autocomplete"
import AddImage from "../../../components/containers/AddImage"
import { EventBus } from "../../../EventBus"
import axios from "../../../plugins/axios"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: {
    SearchLink,
    CreateEdit,
    SDateTime,
    AddImage,
    SAutocomplete
  },
  data() {
    return {
      dense: true,
      hided: false,
      fileName: "",
      file: {},
      urlImg: null,
      searchId: "",
      dictionary: {},
      linkArray: null,
      linkField: null,
      linkSubfield: null,
      linkIndex: null,
      selectId: null,
      render: 1,
      record: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          100: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          200: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              k: [{ ord: "", val: "" }]
            }
          ],
          201: [{ ord: "", n: [{ ord: "", val: "" }], s: [{ ord: "", val: "" }], v: [{ ord: "", val: "" }] }],
          204: [{ ord: "", a: [{ ord: "", val: "" }] }],
          205: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          210: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              t: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          250: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }]
            }
          ],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          310: [{ ord: "", a: [{ ord: "", val: "" }] }],
          315: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          316: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          320: [{ ord: "", a: [{ ord: "", val: "" }] }],
          410: [{ 1: [{ ord: "", val: "" }], ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          411: [{ 1: [{ ord: "", val: "" }], ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          420: [{ ord: "", i: [{ ord: "", val: "" }] }],
          500: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }]
            }
          ],
          505: [
            {
              2: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              6: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          700: [{ ord: "", a: [{ ord: "", val: "" }], n: [{ ord: "", val: "" }] }],
          801: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          855: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          856: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              ord: "",
              d: [{ ord: "", val: "" }],
              link: "", //5f7b25ca94e094b4e20e8daf
              f: [{ ord: "", src_id: "", val: "" }],
              q: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          901: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }]
            }
          ],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "009": [{ ord: "", a: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      recordView: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          100: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          200: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              k: [{ ord: "", val: "" }]
            }
          ],
          201: [{ ord: "", n: [{ ord: "", val: "" }], s: [{ ord: "", val: "" }], v: [{ ord: "", val: "" }] }],
          204: [{ ord: "", a: [{ ord: "", val: "" }] }],
          205: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          210: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              t: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          250: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }]
            }
          ],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          310: [{ ord: "", a: [{ ord: "", val: "" }] }],
          315: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          316: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          320: [{ ord: "", a: [{ ord: "", val: "" }] }],
          410: [{ 1: [{ ord: "", val: "" }], ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          411: [{ 1: [{ ord: "", val: "" }], ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          420: [{ ord: "", i: [{ ord: "", val: "" }] }],
          500: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }]
            }
          ],
          505: [
            {
              2: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              6: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          700: [{ ord: "", a: [{ ord: "", val: "" }], n: [{ ord: "", val: "" }] }],
          801: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          855: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          856: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              ord: "",
              d: [{ ord: "", val: "" }],
              link: "",
              f: [{ ord: "", src_id: "", val: "" }],
              q: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          901: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }]
            }
          ],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "009": [{ ord: "", a: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      schema: {
        dictionary: [
          "all.clnSex",
          "all.clnActIdentitate",
          "all.clnStareCont",
          "all.clnClasaCititor",
          "all.clnStarePermis",
          "all.clnNationalitate",
          "all.clnFormulaAdresare",
          "all.TipAdresa",
          "all.utilizatorProfesie",
          "all.clnNivelPregatire",
          "all.JudeteRomania",
          "all.countriesAlpha3",
          "all.tipTelefon",
          "all.cln_201v"
        ]
      },
      rows: []
    }
  },

  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {
    if (this.schema.dictionary) {
      this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
    }
    const country = []
    this.rows.map(itm => {
      country.push({ name: itm.value, value: itm.value })
    })
    EventBus.$on("dictionary-reload", dict => {
      this.$log("dictionary-reload ", dict)
      if (this.selectId == dict.id) {
        this.getDictionaryValues("all." + dict.dictionary)
        if (dict.select) {
          this.setLinkValue(dict.select)
        }
      }
    })
    // EventBus.$on("table-form-select", value => {
    //   this.$log("selected ", value)
    // })
    //this.$log(JSON.stringify(country))
    // axios.post("anaf_check_tva", [{ cui: 18158683, data: "2020-04-30" }]).then(response => {
    //   this.$log("response ", response)
    // })
  },
  methods: {
    errorMessages(fld, sub, v, items, field) {
      return ""
    },
    validateValue(fld, sub) {
      const rule = []
      //rule.push(v => !!v || this.$t("required"))
      return rule
    },
    checkDuplicate(pre, fld, sub, val) {
      if (val) {
        axios.post("marc/is_duplicated_fs_value/" + pre + "/" + fld + "/" + sub + "/" + val).then(response => {
          this.$set(this.dictionary, fld + sub, response.data == 1)
        })
      }
    },
    validateDuplicate(mnd, dup, v) {
      const rule = []
      if (mnd) {
        rule.push(v => !!v || this.$t("required"))
      }
      if (dup) {
        rule.push(v => this.$t("Valoarea trebuie să fie unică!!!"))
      }
      return rule
    },
    genBarcode(prefix) {
      if (prefix == "cln") {
        axios.get("generate_barcode_client").then(response => {
          this.$log("genBarcode ", response)
          if (response.data.code == 1) {
            this.$set(this.record.fields["500"][0].a[0], "val", response.data.data.barcode)
          }
        })
      } else if (prefix == "inv") {
        axios.get("generate_barcode_inventory").then(response => {
          this.$log("genBarcode ", response)
          if (response.data.code == 1) {
            this.$set(this.record.fields["200"][0].a[0], "val", response.data.data.barcode)
          }
        })
      }
    },
    getInv() {
      axios.get('ebib_loan_active?aggregate={"$where":{},"$sort":{}}&max_results=10').then(response => {
        this.$log("resinvtm ", response)
      })
    },
    createURL(img, fil) {
      this.$set(this.dictionary, img, URL.createObjectURL(fil.target.files[0]))
    },
    fileChanged(e) {
      this.file = e.target.files[0]
      this.urlImg = URL.createObjectURL(this.file)
      this.fileName = this.file.name
    },
    clearFile() {
      this.fileName = ""
      this.file = null
    },
    createAndEdit(e, arr, field, ind) {
      //searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.selectId = Date.now()
      this.$log("createAndEdit ", e)
      this.$refs.createEdit.showData(e, this.selectId)
    },
    checkTVA(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
          }
        })
      }
    },
    loadCUIData(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
            this.record.name = response.data.found[0].denumire
            const adr = response.data.found[0].adresa.split(",")
            if (adr.length > 2) {
              this.record.address.address = response.data.found[0].adresa.replace(adr[0] + "," + adr[1] + ",", "")
            }
            this.record.address.locality = adr[1]
            this.record.address.country = "România"
            if (adr[0].substr(0, 3) == "JUD") {
              const jud = adr[0].substr(5).toLowerCase()
              this.record.address.county = adr[0].substr(5)
              this.dictionary.county.map(itm => {
                if (itm.value.toLowerCase() == jud) {
                  this.record.address.county = itm.value
                }
              })
            } else {
              this.record.address.county = "Bucureşti"
            }
          }
        })
      }
    },
    getdictionary(dic) {
      return this.$store.getters.dictionary(dic)
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        this.$set(this.dictionary, dictionary[1], this.getdictionary("all_" + dictionary[1]))
        // this.dictionary[dictionary[1]] = response.data._items[0].content
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    addSubItem(e, ind) {
      if (ind == null) {
        this.resolvePath(e, this.record).push(JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0])))
      } else {
        this.resolvePath(e, this.record).splice(
          ind,
          0,
          JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0]))
        )
      }
    },
    delSubItem(e, ind) {
      this.resolvePath(e, this.record).splice(ind, 1)
      if (this.resolvePath(e, this.record).length == 0) {
        this.addSubItem(e)
      }
    },
    searchRelationMarc(field, subfield, ind, prefix, ctg) {
      this.linkField = field
      this.linkSubfield = subfield
      this.linkIndex = ind
      //this.$log("srcrel ", this.schemaContent[fields.field + subfield])
      this.searchId = Date.now()
      let where = ""
      let vTable = ""
      if (ctg) {
        where = '"ctg":' + '"' + ctg + '"'
        const ctgview = this.$store.getters.appViewTableCollectionCategory("marc_" + prefix, ctg)
        if (ctgview.length > 0) {
          vTable = ctgview[0]._id
        }
      }
      this.$refs.searchLink.open("ebib_marc_" + prefix, null, this.searchId, {
        where: where,
        viewTable: vTable,
        ctg: ctg
      })
      // EventBus.$emit("search-link", {
      //   resource: "ebib_marc_" + prefix,
      //   field: null,
      //   id: this.searchId,
      //   config: { where: where, viewTable: vTable, ctg: ctg }
      // })
      //this.$refs.searchLink.open(res, fld, this.searchId)
    },
    searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.searchId = Date.now()
      this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setLink(field, subfield, ind, prefix, ctg, e) {
      this.$log("eeee ", e)
    },
    setLinkValue(e) {
      if (this.linkIndex != null) {
        if (this.linkField != null) {
          //this.linkArray[this.linkIndex][this.linkField] = e
          this.$set(this.linkArray[this.linkIndex], this.linkField, e)
        } else {
          if (typeof e === "object") {
            e.iiid = this.idGlobal++
          }
          if (this.linkIndex == -1) {
            this.linkArray.push(e)
          } else {
            //this.linkArray[this.linkIndex] = e
            this.$set(this.linkArray, this.linkIndex, e)
          }
        }
      } else {
        //this.linkArray[this.linkField] = e
        this.$set(this.linkArray, this.linkField, e)
        this.render = Date.now()
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
